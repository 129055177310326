import '../../vendors/layer_handler_ecc/layer_handler_eec'

function changeTab(activeTab, activeTarget) {
  activeTab.classList.add('active')
  Array.from(activeTab.parentElement.children).forEach(sibling => {
    if (sibling !== activeTab) sibling.classList.remove('active')
  })
  activeTarget.classList.add('active')
  Array.from(activeTarget.parentElement.children).forEach(sibling => {
    if (sibling !== activeTarget) sibling.classList.remove('active')
  })
  activeTarget.querySelectorAll('.swiper-container:visible').forEach(function (swiperContainer) {
    if (swiperContainer.swiper) swiperContainer.swiper.update()
  })
  LayerHandlerEec.pushVisibleProducts(
    '.destination-tabs-list .js-tab.active .js-tab.active .card-container .call-to-action a[data-ecc-product]'
  )
}
document.addEventListener('click', function (e) {
  if (e.target.closest('.js-tabs-nav li a')) {
    e.preventDefault()
    const el = e.target.closest('li')
    const target = document.querySelector(el.getAttribute('data-tab'))
    changeTab(el, target)
  }
})
document.addEventListener('click', function (e) {
  if (e.target.closest('.js-link-nav')) {
    e.preventDefault()
    const el = e.target.closest('.js-link-nav')
    const dataTab = el.getAttribute('data-tab')
    const target = document.querySelector(dataTab)
    const activeTab = el.closest('.js-tabs-parent').querySelector(`.js-tabs-nav li[data-tab="${dataTab}"]`)
    changeTab(activeTab, target)
  }
})

document.addEventListener('click', function (e) {
  if (e.target.closest('.js-tabs-actions li')) {
    e.preventDefault()
    const el = e.target.closest('li')
    const wrapper = el.closest('.js-tabs')
    const klass = el.getAttribute('data-klass')

    el.classList.add('active')
    Array.from(el.parentElement.children).forEach(sibling => {
      if (sibling !== el) sibling.classList.remove('active')
    })

    wrapper.className = `js-tabs ${klass}`

    wrapper.querySelectorAll('.swiper-container').forEach(function (swiperContainer) {
      if (swiperContainer.swiper) swiperContainer.swiper.update(true)
    })
  }
})
/**
 * Función que activa la pestaña seleccionada y su contenido
 * @param {element} activeTab
 * @param {element} activeTarget
 */

import on from '../../../src/utils/events_delegation'
import { currentPageType } from '../../utils/page_type_detector.js'
import fastbooking from '../fastbooking/ibh_fastbooking.js'

const dateAlert = document.querySelector('.dates-alert')

const showAlert = () => dateAlert?.classList.remove('hidden')
const hideAlert = () => dateAlert?.classList.add('hidden')

/**
 * Detect click on dates-alert to show date selector in FB
 */

on({
  eventName: 'click',
  selector: '.js-dates-alert .close',
  handler() {
    hideAlert()
  },
})

on({
  eventName: 'click',
  selector: '.js-dates-alert',
  handler(e) {
    if (!e.target.classList.contains('close')) fastbooking.openDates()
    hideAlert()
  },
})

/**
 * Observe if we've reached hotels list to show dates alert
 */
const observeHotelsList = () => {
  const observer = new IntersectionObserver(function (entries) {
    entries.forEach(function (entry) {
      const check_in = fastbooking.state.dates?.check_in
      const check_out = fastbooking.state.dates?.check_out
      if (entry.isIntersecting) {
        if (!check_in || !check_out) showAlert()
      } else {
        hideAlert()
      }
    })
  })

  document.querySelectorAll('.js-hotel-list').forEach(hotel => observer.observe(hotel))
}

/**
 * Show dates alert if no dates selected in results page
 */
const showDatesAlert = () => {
  if (!currentPageType.isDestination() && !currentPageType.isHotel()) return

  const checkIn = fastbooking.state.dates?.check_in
  const checkOut = fastbooking.state.dates?.check_out

  if (checkIn && checkOut) return

  if (currentPageType.isHotel()) return

  observeHotelsList()
}

addEventListener('DOMContentLoaded', () => showDatesAlert())

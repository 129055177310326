/* eslint-disable simple-import-sort/imports */
// GENERAL IMPORTS
import '../../../src/core/global'

/**FASTBOOKING */
import '../../../src/shared/mobile_cta_sticky_bar/mobile_cta_sticky_bar.js'

// JS creado para el destination_breadcrumb
import '../../../src/elements/destination_breadcrumb/destination_breadcrumb'

// BLOCK LAYER
import '../../../src/core/iberapi/block_layer/block_layer.scss'
import '../../../src/vendors/forms/formz'

import '../../../src/shared/logged_user/logged_user'
import '../../../src/shared/cookie_defaults/cookie_defaults'

// VENDORS

import '../../../src/vendors/jquery/jquery.cookie.min'
import '../../../src/vendors/formikation/formikation'
import '../../../src/vendors/rangedatepicker/rangedatepicker'
import '../../../src/vendors/urlParser/urlParser'
import '../../../src/vendors/aria_manager/aria_manager'
import '@fancyapps/fancybox'
import '../../../src/vendors/fancybox/_fancybox.scss'
import '../../../src/vendors/fancybox/fancybox.custom'

// ANALYTICS
import '../../../src/core/analytics/count_down_session_campaign'
import '../../../src/core/analytics/partner_set_cookies'
import '../../../src/core/analytics/callback_utag_data'
import '../../../src/core/analytics/getHotelHeadquarters'
import '../../../src/core/analytics/dataLayerFunctions'
import '../../../src/core/analytics/utag_eec_events'
import '../../../src/core/analytics/utag_events'
import '../../../src/core/analytics/utag_session_counter'
import '../../../src/shared/load_cachestatus_cookie.js'

// CORE IBERAPI
import '../../../src/core/iberapi/partner_shower/partner_shower'
import '../../../src/core/iberapi/visitor_type_detector/visitor_type_detector'
import '../../../src/core/iberapi/lazy_img/lazy_img'

// BEHAVIORAL MODES ENABLED FROM CMS
import '../../../src/core/cancel_online_bookings/force_cc_lightbox'
import '../../../src/core/iberapi/market_simulator/market_simulator'
import '../../../src/core/iberapi/market_simulator/simulate_market'
import '../../../src/blocks/ib_gallery/gallery_init'

// ELEMENTS
import '../../../src/elements/alert/alert'
import '../../../src/elements/badge/badge'
import '../../../src/elements/banner_pwa/banner_pwa'
import '../../../src/elements/breadcrumbs/breadcrumbs.scss'
import '../../../src/elements/filter_tabs/filter_tabs_commons.scss'
import '../../../src/elements/headband/headband'
import '../../../src/elements/info_links/info_links.scss'
import '../../../src/elements/rating_circles/rating_circles.scss'
import '../../../src/elements/sashes/sashes.scss'
import '../../../src/elements/skeleton/skeleton.scss'
import '../../../src/elements/skeleton/skeleton'
import '../../../src/elements/spinner/spinner.scss'
import '../../../src/elements/toggle/toggle'
import '../../../src/elements/tooltip/tooltip'
import '../../../src/elements/user_links/user_links.scss'

import '../../../src/elements/pro_link/pro_link'
import '../../../src/elements/cookie_country/cookie_country'

// Browsing session
import '../../../src/core/iberapi/browsing_session/browsing_session'

// LAYOUT
import '../../../src/layout/header/header'
import '../../../src/layout/mobile_header/mobile_header'

//COOKIES_POLICY_POPUP
import '../../../src/blocks/cookies_content/cookies_content.js'

// Init loader
import '../../../src/elements/wait_loading/wait_loading'

// SHARED
import '../../../src/shared/locale_cookie/locale_cookie'
import '../../../src/core/iberapi/hotels_data/hotels_data' // 1
import '../../../src/shared/segment/segment' // 1
import '../../../src/shared/last_searches/last_searches' // 2
import '../../../src/shared/locale_form/locale_form' // 3
import '../../../src/shared/phone_list/phone_list' // 8
import '../../../src/shared/currency_form/currency_form' // 9
import '../../../src/shared/device_detector/device_detector'
import '../../../src/shared/currency_agency_selector/currency_agency_selector'
import '../../../src/shared/mobile_panel/mobile_panels'
import '../../../src/shared/callmeback/callmeback'
import '../../../src/shared/internal_nav/internal_nav'
import '../../../src/shared/not_logged_booking/not_logged_booking.scss'
import '../../../src/shared/partners/partners.scss'
import '../../../src/shared/opinion_widget/opinion_widget'
import '../../../src/shared/forms/forms'
import '../../../src/shared/hotel_next_opening/hotel_next_opening'
import '../../../src/shared/offer_notifications/offer_notifications'
import '../../../src/shared/truncable/truncable'
import '../../../src/shared/ab_tasty/_ab_tasty'
import '../../../src/shared/input_date/input_date'
import '../../../src/shared/share_lightbox/share_lightbox'
import '../../../src/shared/sort_by/sort_by'
import '../../../src/shared/footer_sticky/footer_sticky'
import '../../../src/shared/audio_generator/audio_generator'
import '../../../src/shared/next_bookings/next_bookings'
import '../../../src/shared/banners/banners'
import '../../../src/shared/floating_fastbooking/floating_fastbooking.scss'
import '../../../src/shared/pill/pill.scss'
import '../../../src/shared/user_menu/user_menu.scss'
import '../../../src/shared/password_field/password_field.scss'
import '../../../src/shared/comp_card/comp_card.scss'
import '../../../src/shared/fancybox_overwrited/fancybox_overwrited.scss'
import '../../../src/shared/shared_social/shared_social'
import '../../../src/shared/chat_agents/chat_agents'
import '../../../src/shared/popup_fia/popup_fia'
import '../../../src/shared/popup_fia/popup_fia.scss'
import '../../../src/shared/dates_alert/dates_alert'
import '../../../src/shared/external_url_modal/external_url_modal'
import '../../../src/shared/external_url_modal/external_url_modal.scss'

import '../../../src/core/iberapi/ready/ready'

// FASTBOOKING
import '../../../src/shared/fastbooking_new_hotel/fastbooking_new_hotel.scss'

// IHG MODALS AND TOOLTIPS
import '../../../src/core/utils/ihg'
import '../../../src/shared/ihg_tooltips/ihg_tooltip'
import '../../../src/shared/ihg_modal/ihg_modal'

import '../../../src/shared/social_login/social_login'

// BLOCKS
import '../../../src/blocks/header_customized/header_customized'
import '../../../src/blocks/offers_list/offers_list'
import '../../../src/blocks/login_registration_lightbox/login_registration_lightbox'
import '../../../src/blocks/home_megabanner_slider/home_megabanner_slider'
import '../../../src/blocks/hotel_collapsible/hotel_collapsible'
import '../../../src/blocks/special_landing_header/special_landing_header'
import '../../../src/blocks/sustainable_info/sustainable_info'

import '../../../src/shared/social_share_builder/social_share_builder'
import '../../../src/blocks/landing_typekit_header/landing_typekit_header'
import '../../../src/blocks/auto_pop_up/auto_pop_up'
import '../../../src/shared/last_searches_cards/last_searches_cards'
import '../../../src/blocks/best_online_rate/best_online_rate'
import '../../../src/shared/side_panel/side_panel'
import '../../../src/blocks/hotel_information_ratings/hotel_information_ratings'
import '../../../src/blocks/flight_plus_hotel/flight_plus_hotel'

// PAGES
import '../../../src/pages/register/register'
import '../../../src/pages/session_page/session_page'
import '../../../src/pages/my_iberostar/facebook_widget'
import '../../../src/pages/hotel_page/hotel_page'

// ADMIN
import '../../../src/core/iberapi/admin_browsing/admin_browsing'

// TEST LOAD BLOCKS
import '../../../src/core/iberapi/block_layer/test_block_layer'

//APP IN
import '../../../../assets/javascripts/web_view'

import '../../../src/blocks/destinations_index_packed/destinations_index'

// AUTH0
import '../../../src/auth0/sso.js'

import 'hls.js'
// PY05355, Header REB - Ficha de hotel 2023, ?test_reb_header=true
import('../../../src/layout/header/reb/header')
// PY05355, Vue Hotel Header Lite
import('../../../src/vue/hotel_header_lite/vue_hotel_header_lite')

import('../../../src/blocks/hotels_info_with_filtering_packed/hotels_info_with_filtering')

import('../../../src/shared/header_sticky_hotel/header_sticky_hotel')

// VUE MAIN NAV
import('../../../src/vue/main_nav/vue_main_nav')

import('../../../src/shared/ibh_fastbooking/ibh_fastbooking_container')

// VUE POPUP
import('../../../src/vue/popup_notification/vue_popup_notification')

function readTealiumCookies(name) {
  var nameEQ = `${name}=`
  var ca = document.cookie.split(';')
  var length = ca.length
  for (var i = 0; i < length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

function isAbTastyAlreadyLoaded() {
  return !!document.querySelector('[data-ab-tasty]')
}

function initAbTasty() {
  const elementFromHtml = document.documentElement
  const abTastyUrl = elementFromHtml.dataset.abTastyUrl
  if (abTastyUrl) {
    if (!isAbTastyAlreadyLoaded()) {
      ;(function (i, s, o, g, r, a, m) {
        i['abtiming'] = 1 * new Date()
        ;(a = s.createElement(o)), (m = s.getElementsByTagName(o)[0])
        a.async = 1
        a.setAttribute('data-ab-tasty', 'true')
        a.src = g
        m.parentNode.insertBefore(a, m)
      })(window, document, 'script', `https:${abTastyUrl}`)
    }
  }
}
initAbTasty()

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'

import { setAsyncDayjsLocale } from '../../core/utils/locale'
import { currentPageType } from '../../utils/page_type_detector'
import fastbooking from '../fastbooking/ibh_fastbooking'

setAsyncDayjsLocale()

dayjs.extend(localizedFormat)
dayjs.extend(customParseFormat)

let headerHeight
let fbBar
let body

function showFbBar() {
  fbBar.style.transform = 'translateY(0)'
  fbBar.classList.add('sticky')
}

function hideFbBar() {
  fbBar.style.transform = ''
  fbBar.classList.remove('sticky')
}

function calcShowHideFbBar() {
  const scrollTop = window.scrollY
  const offsetBottom = headerHeight

  if (body.getAttribute('data-page-type') !== 'hotel') {
    if (scrollTop > offsetBottom) {
      showFbBar()
      return true
    }
    hideFbBar()
    return false
  }

  const headerWrapper = '.hotel-header .hotel-abouts'
  let header = document.querySelector(headerWrapper)

  if (!header) {
    header = document.querySelector('.js-hotel-reb-header')
  }

  if (header) {
    if (scrollTop > header.offsetTop + header.offsetHeight) {
      showFbBar()
      return true
    }
    hideFbBar()
    return false
  }
}

if (IB.currentDevice === 'mobile') {
  const SELECTORS = {
    FB_BAR: '.js-mobile-cta-sticky-bar',
    MOBILE_HEADER: '.mobile-header',
  }

  fbBar = document.querySelector(SELECTORS.FB_BAR)

  body = document.body
  const pageType = body.getAttribute('data-page-type')

  headerHeight = document.querySelector(SELECTORS.MOBILE_HEADER).offsetHeight

  let scrollTimer = 0
  let lastScrollTop = 0
  const delta = 5

  if (pageType === 'bookings') {
    fbBar.classList.add('summary-bar', 'js-summary-bar')
  } else if (pageType === 'hotel') {
    fbBar.querySelector('.js-summary-destination')?.classList.add('label-hidden')
  }

  calcShowHideFbBar()

  window.addEventListener('resize', () => {
    calcShowHideFbBar()
  })

  window.addEventListener('scroll', () => {
    let scroll = window.scrollY
    const bodyCssTop = parseInt(getComputedStyle(body).top.replace('px', ''), 10)

    if (scrollTimer) {
      clearTimeout(scrollTimer)
    }

    if (scroll === 0 && !isNaN(bodyCssTop)) {
      scroll = Math.abs(bodyCssTop)
    }

    if (Math.abs(lastScrollTop - scroll) <= delta) return

    scrollTimer = setTimeout(() => {
      const isVisible = calcShowHideFbBar()
      if (headerHeight && isVisible) {
        if (scroll < lastScrollTop || scroll === 0) {
          fbBar.style.transform = `translateY(${headerHeight}px)`
        } else {
          fbBar.style.transform = 'translateY(0)'
        }
      }

      lastScrollTop = scroll
    }, 25)
  })

  document.querySelector(SELECTORS.FB_BAR)?.addEventListener('click', e => {
    const summaryItem = e.target.closest('.js-summary-item')
    const ctaButton = e.target.closest('.js-cta-btn')

    if (summaryItem) {
      fastbooking.openStickyFastbooking()
    }

    if (ctaButton) {
      if (currentPageType.isHome()) {
        window.scrollTo(0, document.querySelector('#ibh-fastbooking').offsetTop - 65)
      } else {
        fastbooking.openStickyFastbooking()
      }
    }
  })
}
